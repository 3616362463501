@use "styles/mixins/breakpoints" as *;

.background {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 103px;
  background-image: url("/img/HeroBlock/main.jpg");

  @include media-breakpoint-down(s) {
    padding-top: 120px;
    background-image: url("/img/HeroBlock/main-mobile.jpg");
    background-position: center top;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
}

.textBlock {
  margin-left: 35px;

  @include media-breakpoint-down(s) {
    margin-left: 0;
  }
}

.title {
  color: var(--white-palettes-100);
  padding-bottom: 24px;
  white-space: normal;

  @include media-breakpoint-down(s) {
    padding-bottom: 57px;
  }
}

.image {
  display: flex;
  gap: 24px;
  border-radius: 10px;
  width: 316px;
  height: 136px;
  background: var(--red-palettes-90);
  padding: 17px;
  margin-bottom: 67px;

  @include media-breakpoint-down(s) {
    display: none;
  }
}

.text {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--white-palettes-100);
}

.heroItemsWrapper {
  padding-bottom: 16px;

  @include media-breakpoint-down(s) {
    padding-bottom: 48px;
  }
}

.iconWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 8px;
  margin-bottom: 40px;

  img {
    z-index: 1;
  }
}
